import { gql } from '@apollo/client'
import { DO_COSIUM_QUOTE_FIELDS } from 'graphql/fragments/DOCosiumQuoteFields'
import { DO_ORDER_EVENT_FIELDS } from 'graphql/fragments/DOOrderEventFields'
import { ORDER_TOTAL_AMOUNT_FIELDS } from 'graphql/fragments/DOOrderTotalAmountFCFields'
import { DO_PRESCRIPTION_FIELDS } from 'graphql/fragments/DOPrescriptionFields'

export const CQ_GET_ORDER = gql`
  ${DO_PRESCRIPTION_FIELDS}
  ${ORDER_TOTAL_AMOUNT_FIELDS}
  ${DO_ORDER_EVENT_FIELDS}
  ${DO_COSIUM_QUOTE_FIELDS}
  query CQ_GET_ORDER($id: Int!) {
    CQgetOrder(id: $id) {
      edges{
        node {
            order {
                id
                key
                comment
                orderType
                workflowState
                cosiumQuotes {
                    ...DOCosiumQuoteFields
                }
                orderLines {
                ... on object_DOOrderLine {
                    id
                    key
                    visionType
                    treatmentFormInUse
                    insuranceInvoiceInUse
                    order {
                    ... on object_DOOrder {
                        id
                        orderType
                        orderLines {
                        ... on object_DOOrderLine {
                            id
                            prescription {
                            ...DOPrescriptionFields
                            }
                            items {
                            ... on object_DOOrderLineItem {
                                id
                            }
                            }
                        }
                        }
                    }
                    }
                    prescription {
                    ...DOPrescriptionFields
                    }
                    status
                    state
                    stockState
                    items {
                        ... on object_DOOrderLineItem {
                            id
                            label
                            comments
                            quantity
                            unitAmount {
                            value
                            }
                            hometrialProductAmount {
                            value
                            }
                            item {
                                ... on fieldcollection_DOOLItemLensFC {
                                    eyeSide
                                    lens {
                                        ... on object_DOLens {
                                            id
                                            lensDetails
                                            salePrice {
                                            value
                                            }
                                            basePrice {
                                            value
                                            }
                                            cosiumCode
                                        }
                                    }
                                }
                                ... on fieldcollection_DOOLItemFrameFC {
                                    frame {
                                        ... on object_DOFrame {
                                            id
                                            imageUrls
                                            gtin
                                            images {
                                                image {
                                                    fullpath
                                                }
                                            }
                                            brand {
                                                ... on object_DOBrand {
                                                    id
                                                    name
                                                }
                                            }
                                            name
                                            gtin
                                            sku
                                            idmc
                                            color {
                                                ... on object_DOColor {
                                                    label
                                                }
                                            }
                                            supplier {
                                                ... on object_DOSupplier {
                                                    name
                                                }
                                            }
                                            category {
                                                ... on object_DOFrameCategory {
                                                    id
                                                    rim
                                                    parentCategory {
                                                        ... on object_DOFrameParentCategory {
                                                            label
                                                            isSunglasses
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                ... on fieldcollection_DOOLItemAccessoryFC {
                                    accessory {
                                        ... on object_DOAccessory {
                                            id
                                            imageUrls
                                            gtin
                                            images {
                                                image {
                                                    fullpath
                                                }
                                            }
                                            brand {
                                                ... on object_DOBrand {
                                                    id
                                                    name
                                                }
                                            }
                                            name
                                            gtin
                                            sku
                                            idmc
                                            color {
                                                ... on object_DOColor {
                                                    label
                                                }
                                            }
                                            supplier {
                                                ... on object_DOSupplier {
                                                    name
                                                }
                                            }
                                            category {
                                                ... on object_DOAccessoryCategory {
                                                    id
                                                    parentCategory {
                                                        ... on object_DOAccessoryParentCategory {
                                                            label
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                ... on fieldcollection_DOOLItemVoucherFC {
                                    voucher {
                                        ... on object_DOVoucher {
                                            id
                                            voucherCode
                                            isGeneric
                                            voucherTemplate {
                                                ... on object_DOVoucherTemplate {
                                                    genericCode
                                                }
                                            }
                                        }
                                    }
                                }
                                ... on fieldcollection_DOOLItemLensReplacementFC {
                                    lensReplacement {
                                        ... on object_DOLensReplacement {
                                            id
                                            imageUrls
                                            images {
                                                image {
                                                    fullpath
                                                }
                                            }
                                            name
                                            category {
                                                ... on object_DOFrameCategory {
                                                    id
                                                    rim
                                                }
                                            }
                                        }
                                    }
                                }
                                ... on fieldcollection_DOOLItemGenericFC {
                                    genericItem {
                                        ... on object_DOGenericItem {
                                        key
                                        id
                                        label
                                        itemType
                                        amount {
                                            value
                                        }
                                        isNegative
                                        }
                                    }
                                }
                                ... on fieldcollection_DOOLItemShippingFC {
                                    shippingFee {
                                        ... on object_DOShippingFee {
                                            id
                                        }
                                    }   
                                }
                            }
                        }
                    }
                }
                }
                customer {
                    ... on object_DOCustomer {
                        id
                    }
                }
                workflowState
                events {
                ...DOOrderEventFields
                }
                totalAmountHistory {
                ...DOOrderTotalAmountFCFields
                }
            }
            calculators {
                cosiumId
                isPaid
                #UNCOMMENT IF NEEDED
                #orderPaidDate  
                #orderSentToStoreDate 
                #orderInvoicedDate  
                #payState 
                #mailsSentCount  
                totalPaidAmount
                #productCount
                beneficiaryId
                hasLensReplacement
            }
        }
        }
    }
  }
`
